import { Container } from '@/components/container';
import { EnterAnimation } from '@/components/enter-animaton';
import { ExternalLink } from '@/components/external-link';
import { GridContainer } from '@/components/grid-container';
import { Layout } from '@/components/layout';
import { LinkWithArrow } from '@/components/link-with-arrow';
import { LinkNextProject } from '@/components/project/link-next-project';
import { ProjectCover } from '@/components/project/project-cover';
import { ProjectInfo } from '@/components/project/project-info';
import { Statistics } from '@/components/serializers/statistics';
import { ToolkitModalProps } from '@/components/toolkit-modal';
import { components } from '@/lib/sanity/portable-text';
import { urlForImage } from '@/lib/sanity/sanity';
import { styled } from '@/stitches.config';
import {
  PortableText,
  PortableTextComponentsProvider,
} from '@portabletext/react';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CaseTransitionOut } from '@/components/caseTransitions';
import { useCaseTransitionStore } from '@/hooks/useCaseTransitionStore';
import { Badge, getBadge } from '@/lib/sanity/queries/get-badge';
import { getProjects } from '@/lib/sanity/queries/get-projects';
import {
  ToolkitItem,
  getToolkitItem,
} from '@/lib/sanity/queries/get-toolkit-item';
import { getProject, Project } from '@/lib/sanity/queries/get-project';

const ToolkitModal = dynamic<ToolkitModalProps>(
  () =>
    import('@/components/toolkit-modal/toolkit-modal').then(
      (mod) => mod.ToolkitModal
    ),
  { ssr: true }
);

type ProjectPageProps = InferGetStaticPropsType<typeof getStaticProps>;

const ProjectContent = styled('div', {
  paddingTop: '$7',
  paddingBottom: '$9',

  '@bp4': {
    paddingBottom: '$11',
  },
});

function ProjectPage({ project, toolkitItem, badge }: ProjectPageProps) {
  const {
    metadata,
    subtitle,
    externalLink,
    coverImage,
    coverVideo,
    coverVideoPlaceholder,
    statistics,
    clients,
    partners,
    deliverables,
    awards,
    challenge,
    value,
    content,
    nextProject,
  } = project;

  const router = useRouter();

  const { isCaseTransitionReady } = useCaseTransitionStore();

  /**
   * Wait, what's this?
   * Well, because we're playing with the modal window in a drill-down page,
   * we're using catch-all routes for project pages. They work really well,
   * but the DX is a bit iffy imho. I'd rather see named paths, but with this
   * array approach we're preventing a re-render of the page when the
   * modal window opens or closes, so... required evil API here.
   */
  const slug = router.query.slug || [];

  /**
   * First item in the slug array is the project slug
   */
  const projectId = slug[0];

  /**
   * By request the image through the next.js image proxy, we save on traffic to the Sanity CDN
   */
  const ogImage = metadata.openGraphImage
    ? `https://www.cleverfranke.com/_next/image?url=${urlForImage(
        metadata.openGraphImage
      ).url()}&w=1200&q=75`
    : `https://cleverfranke.com/api/og?title=${metadata.title}`;
  /**
   * We only set the modal active when we've received a toolkit item slug. This is the third item
   * in the query array. getStaticPaths and getStaticProps return a 404 for invalid slugs, so we
   * can keep this template naive and just assume it's a valid slug
   */

  return (
    <>
      <PortableTextComponentsProvider components={components}>
        <Layout
          title={metadata.title || undefined}
          description={metadata.description || undefined}
          ogImage={ogImage}
          badgeData={badge}
        >
          <ProjectCover
            projectDescription={subtitle}
            projectCoverImage={coverImage}
            projectCoverVideo={coverVideo}
            placeholderImageUrl={coverVideoPlaceholder}
          />

          <ProjectContent>
            {statistics.length > 0 && (
              <EnterAnimation css={{ marginBottom: '$10' }}>
                <GridContainer>
                  <Statistics statistics={statistics} />
                </GridContainer>
              </EnterAnimation>
            )}

            <EnterAnimation>
              <GridContainer>
                <ProjectInfo
                  clients={clients}
                  partners={partners}
                  awards={awards}
                  deliverables={deliverables}
                  challenge={challenge}
                  value={value}
                />
              </GridContainer>
            </EnterAnimation>

            {/* Every element that gets returned from portable text gets wrapped in a special grid container.
             * That is why we can exclude it from the <Container> that's wrapping the structured data!
             */}
            <PortableText value={content} />

            {externalLink && externalLink.link && externalLink.text && (
              <>
                <Container
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0,

                    '@bp2': {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '$10',
                    },
                  }}
                >
                  <ExternalLink
                    link={externalLink.link}
                    text={externalLink.text}
                  />
                </Container>
              </>
            )}
          </ProjectContent>

          <div>
            <LinkWithArrow href="/work">Back to work overview</LinkWithArrow>
            {nextProject && <LinkNextProject project={nextProject} />}
          </div>
        </Layout>
        <ToolkitModal
          toolkitItem={toolkitItem}
          handleClose={() => {
            router.replace(`/project/${projectId}`, undefined, {
              shallow: true,
              scroll: false,
            });
          }}
          isOpen={!!slug[2]}
        />
      </PortableTextComponentsProvider>

      {isCaseTransitionReady() && <CaseTransitionOut />}
    </>
  );
}

export default ProjectPage;

const PRERENDER_TOOLKIT_PAGES = false;

export const getStaticPaths: GetStaticPaths = async () => {
  const projects = await getProjects(false);

  // Get the paths we want to pre-render based on all project information in sanity
  const paths = [] as {
    params: {
      slug: string[];
    };
  }[];

  projects.map((project) => {
    // We always need a root path

    paths.push({
      params: {
        slug: [project.slug],
      },
    });

    // @TODO we need to pre-render all the nested routes too, e.g. the subkit items
    // Nested loop should be good for that, right?

    if (PRERENDER_TOOLKIT_PAGES) {
      if (project.toolkitItems) {
        const allToolkitSlugs = project.toolkitItems.map(
          (item) => item.current
        );
        allToolkitSlugs.map((toolkitItemSlug) => {
          paths.push({
            params: {
              slug: [project.slug, 'toolkit', toolkitItemSlug],
            },
          });
        });
      }
    }
  });

  // We'll pre-render only these paths at build time.
  // { fallback: blocking } means new routes are loaded on the server
  // before returning a static version to the client.
  return { paths, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<
  {
    project: Project;
    toolkitItem: ToolkitItem | null;
    preview: boolean;
    badge: Badge;
  },
  {
    slug: string[];
  }
> = async (context) => {
  const { preview = false, params } = context;

  if (!params?.slug) {
    return {
      notFound: true,
    };
  }

  const { slug } = params;

  const project = await getProject(preview, slug[0]);

  let toolkitItemId;
  let toolkitItem: ToolkitItem | null = null;
  if (slug.length > 2) {
    toolkitItemId = slug[2];
    toolkitItem = await getToolkitItem(preview, toolkitItemId);
  }

  // Redirect to 404 if the provided category slug from the URL
  // is invalid according to sanity
  if (!project?._id) {
    return { notFound: true, revalidate: 60 * 60 };
  }

  // If we defined /toolkit/ but we didn't define /toolkit/item-name, we also want to show a 404.
  // TBD: Do we redirect to the normal project ID?
  if (slug[1] && !slug[2]) {
    return {
      notFound: true,
    };
  }

  // If all parameters are defined, we still need to check if the toolkit is defined in Sanity
  if (slug.length > 2) {
    if (!toolkitItem?._id) {
      return { notFound: true, revalidate: 60 * 60 };
    }
  }

  const badge = await getBadge(preview);

  return {
    props: { project, preview, toolkitItem, badge },
    //           s * min
    revalidate: 60 * 30,
  };
};
