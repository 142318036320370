import { CSS, styled } from '@/stitches.config';
import { useState } from 'react';
import { Box } from './box';

interface ProjectCoverVideoProps {
  videoUrl: string;
  placeholderImageUrl?: string;
  css?: CSS;
}

export function CoverVideo({
  videoUrl,
  placeholderImageUrl,
  css,
}: ProjectCoverVideoProps) {
  const [isVideoDataLoaded, setIsVideoDataLoaded] = useState(false);

  return (
    <Box css={{ position: 'relative', ...css }}>
      {placeholderImageUrl && (
        <PosterBlurImage
          css={{ backgroundImage: `url(${placeholderImageUrl})` }}
        />
      )}

      <StyledVideo
        onLoadedData={() => setIsVideoDataLoaded(true)}
        src={videoUrl}
        controls={false}
        autoPlay
        loop
        playsInline
        muted
        css={{ opacity: isVideoDataLoaded ? 1 : 0 }}
      />
    </Box>
  );
}

const PosterBlurImage = styled('div', {
  position: 'absolute',
  inset: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const StyledVideo = styled('video', {
  objectFit: 'cover',
  position: 'absolute',
  height: '100%',
  width: '100%',
  transition: 'opacity 1s ease-in',
});
