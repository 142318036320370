import { CapsizedText } from '@/components/text';
import { styled } from '@/stitches.config';
import Link from 'next/link';
import { Underline, underlineHoverStyles } from './underline';

type Props = {
  children: React.ReactNode;
  href: string;
  scroll?: boolean;
};

export function LinkWithArrow({ children, href, scroll = true }: Props) {
  return (
    <Link href={href} passHref scroll={scroll}>
      <Anchor>
        <Arrow>{'<-'}</Arrow>
        <CapsizedText>{children}</CapsizedText>
        <Underline />
      </Anchor>
    </Link>
  );
}

const Arrow = styled(CapsizedText, {
  fontFeatureSettings: '"dlig" 1',
  transition: 'transform 0.12s ease-in-out',
});

const Anchor = styled('a', {
  position: 'relative',
  display: 'inline-flex',
  gap: '$2',
  alignItems: 'center',
  color: '$foreground',
  borderBottom: 0,
  fontSize: '$2',
  textDecoration: 'none',
  mx: '$4',
  py: '$2',
  overflow: 'hidden',

  '@bp3': {
    mx: '$6',
    paddingBottom: '$3',
  },

  '@bp4': {
    mx: '$8',
  },

  svg: {
    '@motion': {
      transition: 'transform 0.2s',
    },
  },

  '&:hover, &:active': {
    [`${Underline} > span`]: underlineHoverStyles,
  },

  '&:focus': {
    [`${Underline} > span`]: underlineHoverStyles,
  },
});
