import { GridContainer } from '@/components/grid-container';
import { Text } from '@/components/text';
import { useHeaderStore } from '@/hooks/useHeaderStore';
import { Project } from '@/lib/sanity/queries/get-project';
import { urlForImage } from '@/lib/sanity/sanity';
import { item } from '@/lib/transitions';
import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import NextImage from 'next/image';
import { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { curve, delay, duration, yOffset } from '../enter-animaton';
import { CoverVideo } from '../cover-video';

interface ProjectCoverProps {
  projectCoverImage: Project['coverImage'];
  projectCoverVideo: Project['coverVideo'];
  projectDescription: string;
  placeholderImageUrl: Project['coverVideoPlaceholder'];
}

export function ProjectCover({
  projectCoverImage,
  projectDescription,
  projectCoverVideo,
  placeholderImageUrl,
}: ProjectCoverProps) {
  return (
    <ProjectCoverWrapper description={projectDescription}>
      {projectCoverVideo && (
        <CoverVideo
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          videoUrl={projectCoverVideo}
          placeholderImageUrl={placeholderImageUrl}
        />
      )}
      {projectCoverImage && !projectCoverVideo && (
        <ProjectCoverImage image={projectCoverImage} />
      )}
    </ProjectCoverWrapper>
  );
}

interface ProjectWrapperProps {
  description: string;
  children?: React.ReactNode;
}

function ProjectCoverWrapper({ children, description }: ProjectWrapperProps) {
  /**
   * Checking the state whether the cover image/video is out of the view
   * The state is then saved in 'useCoverIntersection' store
   * To be used in the header component to change the colour of the Logo
   */
  const { setIsIntersectingWithCover } = useHeaderStore();

  const intersectionOption = {
    root: null,
    rootMargin: '0px',
    treshold: 0.1,
  };

  // Checks if the ProjectCover component is over the white/dark part of the page so we can invert some colors
  const ref = useRef<HTMLDivElement | null>(null);
  const intersection = useIntersection(ref, intersectionOption);

  useEffect(() => {
    if (typeof intersection?.isIntersecting === 'boolean') {
      setIsIntersectingWithCover(intersection.isIntersecting);
    }
  }, [intersection, setIsIntersectingWithCover]);

  return (
    <Wrapper ref={ref}>
      {children}
      <motion.div variants={item}>
        <GridContainer
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            '@bp6': {
              paddingRight: '15vw',
            },
          }}
        >
          <Text
            as={motion.h1}
            size={{
              '@initial': 8,
              '@bp3': 11,
              '@bp5': 14,
            }}
            initial={{ opacity: 0, y: yOffset }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: duration,
              delay: delay + 0.2,
              ease: curve,
            }}
            css={{
              display: 'block',
              zIndex: 1,
              color: 'white',
              '@bp3': {
                gridColumn: 'wide',
              },
              // Targets desktops with a display configuration of 150% that have aprx 947px of view height
              // To ensure long case study titles are not too big and doesn't bleed into the header
              '@media only screen and (max-height: 940px)': {
                '@bp5': {
                  fontSize: '$10',
                },
              },
            }}
          >
            {description}
          </Text>
        </GridContainer>
      </motion.div>
    </Wrapper>
  );
}

interface ProjectCoverImageProps {
  image: NonNullable<Project['coverImage']>;
}

function ProjectCoverImage({ image }: ProjectCoverImageProps) {
  const imageUrl = urlForImage(image).url();

  return (
    <motion.div
      inherit={false}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
    >
      <CoverOverlay />
      <CoverImage
        src={imageUrl}
        layout="fill"
        placeholder="blur"
        blurDataURL={image.metadata.lqip}
        alt=""
      />
    </motion.div>
  );
}

const Wrapper = styled('div', {
  height: '80vh',
  position: 'relative',

  '&::after': {
    content: '',
    position: 'absolute',
    zIndex: '$default',
    top: 0,
    left: 0,
    right: 0,
    height: '$10',
  },
});

const CoverImage = styled(NextImage, {
  objectFit: 'cover',
  position: 'absolute',
  inset: 0,
});

const CoverOverlay = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.2)',
  zIndex: 1,
});
